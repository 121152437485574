import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchAllProjects = createAsyncThunk(
  "getAllProjects",
  async ({ token, instanceLinks }, { rejectWithValue }) => {
    try {
      const responses = await Promise.all(
        instanceLinks.map((link) =>
          axios.get(`${link}/api/Project/GetAll`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(response => ({
            data: response.data.data,
            source: link
          }))
        )
      );

      const combinedData = responses.flatMap((response) => 
        response.data.map(project => ({
          ...project,
          source: response.source
        }))
      );
      return combinedData;
    } catch (error) {
      console.error("Error fetching projects:", error);
      return rejectWithValue(
        error.response?.data?.title ||
          error?.message ||
          "Error fetching projects"
      );
    }
  }
);

const fetchAllProjectsSlice = createSlice({
  name: "allProjects",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllProjects.pending, (state) => {
      state.isLoading = true;
      state.errorMessage = "";
    });
    builder.addCase(fetchAllProjects.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchAllProjects.rejected, (state, action) => {
      state.errorMessage = action.payload;
      state.isError = true;
    });
  },
});

export default fetchAllProjectsSlice.reducer;