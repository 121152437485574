import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  Suspense,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { TableCell, IconButton, Link } from "@mui/material";
import Header from "../../components/headers/Header";
import SubHeader from "../../components/subHeader/SubHeader";
import InfoModal from "../../components/infoModal/InfoModal";
import ErrorModal from "../../components/error/errorModal";
import Instance from "./Instance";
import { fetchAllProjects } from "../../features/slices/projectSlice";
import { fetchAllInstances } from "../../features/slices/allInstances";
import { fetchKba } from "../../features/slices/kbaSlice";
import { useAuthentication } from "../../constants/config/useAuthentication";
import { checkAdminUsers } from "../../features/slices/UserData";
import language from "../../constants/languages/en/translations.json";
import { View } from "../../constants/icons/Icons";
import "./AllProjects.scss";
import { useMsal } from "@azure/msal-react";
import config from "../../config";

const ProjectsTable = React.lazy(() =>
  import("../../components/projectsTable/ProjectsTable")
);

const useFetchData = (token, dispatch, setInstanceDataFetched) => {
  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        await dispatch(fetchAllInstances({ token }));
        setInstanceDataFetched(true);
      }
    };
    fetchData();
  }, [dispatch, token, setInstanceDataFetched]);
};

const useFetchProjects = (
  token,
  dispatch,
  instanceDataFetched,
  instanceData
) => {
  useEffect(() => {
    const fetchProjects = async () => {
      if (token && instanceDataFetched && instanceData?.data) {
        const instanceLinks = instanceData.data.map(
          (instance) => instance.instanceLink
        );
        await dispatch(fetchAllProjects({ token, instanceLinks }));
      }
    };
    fetchProjects();
  }, [dispatch, token, instanceDataFetched, instanceData]);
};

const useCombinedData = (instanceData, allProjectsData) => {
  const [projectsByInstance, setProjectsByInstance] = useState([]);

  useEffect(() => {
    if (instanceData?.data && allProjectsData) {
      const combinedData = allProjectsData.map((project) => {
        const matchedInstance = instanceData.data.find(
          (instance) => instance.instanceLink === project.source
        );
        return {
          ...project,
          instanceName: matchedInstance?.instanceName || "Unknown Instance",
          instanceUiLink: matchedInstance?.instanceUiLink || "",
        };
      });
      setProjectsByInstance(combinedData);
    }
  }, [instanceData, allProjectsData]);

  return projectsByInstance;
};

const setCookies = (token, name, email, userId, photo) => {
  const domain = config.appDomain;
  document.cookie = `authToken=${token}; path=/; domain=${domain}; secure; samesite=None;`;
  document.cookie = `name=${name}; path=/; domain=${domain}; secure; samesite=None;`;
  document.cookie = `email=${email}; path=/; domain=${domain}; secure; samesite=None;`;
  document.cookie = `userId=${userId}; path=/; domain=${domain}; secure; samesite=None;`;
  document.cookie = `photo=${photo}; path=/; domain=${domain}; secure; samesite=None;`;
};

const AllProjects = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.jwtToken);
  const storedLanguage = localStorage.getItem("language") ?? "EN";
  const userDetails = useAuthentication();
  const instanceData = useSelector((state) => state.allInstances.data);
  const isInstanceApiError = useSelector(
    (state) => state.allInstances.isInstanceApiError
  );
  const isInstanceApiLoading = useSelector(
    (state) => state.allInstances.isInstanceApiLoading
  );
  const instanceApiErrorMessage = useSelector(
    (state) => state.allInstances.instanceApiErrorMessage
  );
  const allProjectsData = useSelector((state) => state.allProjects.data);
  const isLoading = useSelector((state) => state.allProjects.isLoading);
  const isError = useSelector((state) => state.isError);
  const errorMessage = useSelector((state) => state.errorMessage);
  const userState = useSelector((state) => state.user);
  const photo = userState.photo;

  const [selectedOption, setSelectedOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [infoModalData, setInfoModalData] = useState({
    title: "",
    description: "",
    kbaCode: "",
    languageCode: "",
  });
  const [isNavigating, setIsNavigating] = useState(false);
  const [instanceDataFetched, setInstanceDataFetched] = useState(false);

  const kbaID = "1";
  const version = 1;

  useFetchData(token, dispatch, setInstanceDataFetched);
  useFetchProjects(token, dispatch, instanceDataFetched, instanceData);

  const projectsByInstance = useCombinedData(instanceData, allProjectsData);

  const filteredData = useMemo(() => {
    if (!searchQuery) return projectsByInstance;
    const query = searchQuery.toLowerCase();
    return projectsByInstance.filter(
      (item) =>
        item.name.toLowerCase().includes(query) ||
        item.clientName.toLowerCase().includes(query) ||
        item.instanceName.toLowerCase().includes(query)
    );
  }, [projectsByInstance, searchQuery]);

  const handleInfoClick = useCallback(
    async (language = storedLanguage) => {
      try {
        const langCode = language.toUpperCase();
        const response = await dispatch(
          fetchKba({ token, langCode, kbaID, version })
        ).unwrap();
        if (response.success) {
          const result = response.data;
          setInfoModalData({
            kbaCode: `KBA #${kbaID}`,
            title: result.name,
            languageCode: result.language,
            description: result.context,
          });
          setIsInfoModalOpen(true);
        }
      } catch (error) {
        console.error("Fetching KBA failed:", error);
        alert(
          "Fetching KBA failed: " + (error.message || "Unknown error occurred")
        );
      }
    },
    [dispatch, token, storedLanguage]
  );

  const handleCloseModal = () => setIsInfoModalOpen(false);
  const handleError = () => navigate("/");

  const handleSearch = useCallback((event) => {
    setSearchQuery(event);
  }, []);

  const columnsToDisplay = useMemo(
    () => [
      { colName: "name", label: t("Project"), showSorting: true },
      { colName: "clientName", label: t("Client"), showSorting: true },
      { colName: "instanceName", label: t("Instance"), showSorting: false },
      { colName: "Action", label: t("Action"), showSorting: false },
    ],
    [t]
  );

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const toggleModal = () => setIsOpen((prev) => !prev);

  const handleInstanceCancel = () => setIsOpen(false);

  const handleButtonClick = useCallback(
    (row) => {
      const {
        username: email,
        name,
        localAccountId: userId,
      } = userDetails?.userDetails || {};
      const projectId = row?.id;
      if (row.instanceUiLink && projectId && token) {
        setIsNavigating(true);
        setCookies(token, name, email, userId, photo);
        window.location.href = `${row.instanceUiLink}/project-home/${projectId}`;
      }
    },
    [token, userDetails, photo]
  );

  const handleOnNext = useCallback(
    async (row) => {
      const {
        username: email,
        name,
        localAccountId: userId,
      } = userDetails?.userDetails || {};
      if (!email) {
        console.error("User details are not available");
        return;
      }
      try {
        setIsNavigating(true);
        setCookies(token, name, email, userId, photo);
        const response = await dispatch(
          checkAdminUsers({ emailId: email, token })
        ).unwrap();
        if (response.data === true) {
          const accounts = instance.getAllAccounts();
          if (accounts.length > 0) {
            try {
              const tokenResponse = await instance.acquireTokenSilent({
                scopes: ["User.ReadBasic.All"],
                account: accounts[0],
              });
              const accessToken = tokenResponse.accessToken;
              document.cookie = `accessToken=${accessToken}; path=/; domain=${config.appDomain}; secure; samesite=None;`;
              window.location.href = `${row.instanceUiLink}/create-new-project`;
            } catch (error) {
              console.error("Error fetching AD users:", error);
            }
          }
        } else {
          alert("You are not authorized to access this page.");
          setIsNavigating(false);
        }
      } catch (error) {
        console.error("Error checking admin access:", error);
        setIsNavigating(false);
      }
    },
    [dispatch, instance, photo, userDetails, token]
  );

  const generateRowKey = (row) => row.id;

  const getCellStyle = (column) => {
    switch (column) {
      case "Action":
        return { textAlign: "right", width: "150px", paddingRight: "7%" };
      case "instanceName":
        return { textAlign: "center", width: "215px", paddingRight: "18%" };
      default:
        return { textAlign: "left", width: "250px", paddingLeft: "2%" };
    }
  };

  const renderTableCell = (column, value, row) => {
    const cellStyle = getCellStyle(column);
    if (column === "instanceName") {
      return (
        <TableCell key={column} style={cellStyle}>
          <Link href={row.instanceUiLink}>{row.instanceName}</Link>
        </TableCell>
      );
    }

    if (column === "Action") {
      return (
        <TableCell key={column} style={cellStyle}>
          <IconButton onClick={() => handleButtonClick(row)}>
            <View />
          </IconButton>
        </TableCell>
      );
    }

    return (
      <TableCell key={column} style={cellStyle}>
        {value}
      </TableCell>
    );
  };

  useEffect(() => {
    setIsNavigating(false);
  }, [navigate]);

  if (isError || isInstanceApiError) {
    return (
      <ErrorModal
        setName={t("Error")}
        labelText={errorMessage || instanceApiErrorMessage}
        handleButtonClick={handleError}
        deleteButtonLabel={t("Reload")}
      />
    );
  }

  return (
    <div className="all-projects-page">
      <Header />
      <div className="all-projects-container">
        {(isLoading || isInstanceApiLoading || isNavigating) && (
          <MotifProgressLoader className="loader" show variant="default" />
        )}
        {isOpen && (
          <Instance
            instanceHeader={t("instanceHeader")}
            instanceNext={t("instanceNext")}
            instanceModalDescription={t("instanceModalDescription")}
            selectInstance={t("selectInstance")}
            handleInstanceCancel={handleInstanceCancel}
            handleInfoClick={handleInfoClick}
            onNext={handleOnNext}
            instanceData={instanceData}
          />
        )}
        <div className="border-box">
          <SubHeader
            title={t("AllProjects")}
            selectedOption={selectedOption}
            addButtonText={language.NewProjectEN}
            onChangeSearchInput={handleSearch}
            onOptionChange={handleOptionChange}
            defaultSelection={language.DefaultEN}
            onClickNewProject={toggleModal}
            disableButton={isLoading || isInstanceApiLoading}
          />
          <Suspense
            fallback={
              <MotifProgressLoader className="loader" show variant="default" />
            }
          >
            <ProjectsTable
              columns={columnsToDisplay}
              data={filteredData}
              itemsPerPage={5}
              generateRowKey={generateRowKey}
              getCellStyle={getCellStyle}
              renderTableCell={renderTableCell}
              handleButtonClick={handleButtonClick}
            />
          </Suspense>
        </div>
      </div>
      <InfoModal
        isOpen={isInfoModalOpen}
        onClose={handleCloseModal}
        data={infoModalData}
        handleInfoClick={handleInfoClick}
      />
    </div>
  );
};

export default AllProjects;
