import React, { useEffect, useState } from "react";

const Footer = () => {
  const [version, setVersion] = useState("");

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const response = await fetch("/version.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setVersion(data.version);
      } catch (error) {
        console.error("Error fetching version:", error);
      }
    };

    fetchVersion();
  }, []);

  return (
    <footer>
      <p>Version: {version}</p>
    </footer>
  );
};

export default Footer;
