import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, setUser } from "../../features/slices/HomeSlice";
import "./Header.scss";
import notice from "../../assets/files/PrivacyNoticeESGSuite3.0.pdf";
import { useMsal } from "@azure/msal-react";
import { logOutToken } from "../../features/slices/UserData";
import MenuIcon from "@mui/icons-material/Menu";
import {
  MotifButton,
  MotifAvatar,
  MotifDropdownItem,
  MotifDropdown,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import { PrivacyNote, HelpDesk } from "../../constants/icons/Icons";
import EYHeaderLogo from "../../assets/images/HeaderLogo.png";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const languageOptions = [
  { value: "en", label: "EN" },
  { value: "es", label: "ES" },
];

const signOutClickHandler = async (instance) => {
  const logoutRequest = {
    postLogoutRedirectUri:
      process.env.REACT_APP_BASE_URL || window.location.origin,
    navigateToLoginRequestUrl: false,
  };
  await instance.logoutRedirect(logoutRequest);
};

const Header = () => {
  const { instance } = useMsal();
  const { t, i18n } = useTranslation();
  const userState = useSelector((state) => state.user);
  const token = useSelector((state) => state.user.jwtToken);
  const name = userState.user?.name;
  const photo = userState.photo;
  const dispatch = useDispatch();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userState.user) {
      const storedUserData = localStorage.getItem("user");
      if (storedUserData) {
        const userData = JSON.parse(storedUserData);
        dispatch(setUser(userData));
      }
      const storedLanguage = localStorage.getItem("language");
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      } else {
        const preferredLanguage = "en";
        i18n.changeLanguage(preferredLanguage);
        localStorage.setItem("language", preferredLanguage);
      }
    }
  }, [dispatch, i18n, userState.user]);

  const deleteAllCookies = () => {
    const cookies = document.cookie.split(";");
    const domainParts = window.location.hostname.split(".");
    const domain =
      domainParts.length > 1
        ? domainParts.slice(-2).join(".")
        : window.location.hostname;

    cookies.forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${domain}`;
    });
  };

  const handleSignOut = async () => {
    const controller = new AbortController();
    setLoading(true);
    try {
      await new Promise((resolve) => {
        dispatch(logoutUser());
        resolve();
      });

      await new Promise((resolve, reject) => {
        dispatch(logOutToken(token))
          .then((response) => {
            if (response.error) {
              reject(response.error);
            } else {
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });

      localStorage.clear();
      sessionStorage.clear();
      deleteAllCookies();
      await signOutClickHandler(instance);
    } catch (error) {
      console.error("Error during sign-out", error);
    } finally {
      controller.abort();
      setLoading(false);
    }
  };

  const handleLanguageChange = (selectedOption) => {
    const language = selectedOption.value;
    i18n.changeLanguage(language);
    sessionStorage.setItem("language", language);
  };

  // Common Function
  const renderMenuItems = (isMobile = false) => (
    <>
      <li>
        <MotifButton
          className="header-motif-body"
          onClick={() => window.open(notice, "_blank")}
          data-testid={
            isMobile ? "mobile-privacy-note-button" : "privacy-note-button"
          }
        >
          <PrivacyNote />
          {t("PrivacyNote")}
        </MotifButton>
      </li>
      <li>
        <a href="mailto:esgsuite@ey.com" className="no-underline">
          <MotifButton
            className="header-motif-body"
            data-testid="help-desk-button"
          >
            <HelpDesk />
            {t("HelpDesk")}
          </MotifButton>
        </a>
      </li>
    </>
  );

  return (
    <header className="header-container">
      <div>
        <a href="/" aria-label="Home" className="logo-title">
          <img src={EYHeaderLogo} alt="Logo" width="40" height="40" />
          <h1 className="brand-title">{t("Title")}</h1>
        </a>
      </div>
      <nav className="nav-items">
        <ul>{renderMenuItems()}</ul>
      </nav>
      <div className="right-menu">
        <button
          className="burger-menu"
          onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
          data-testid="menu-icon"
          aria-label="Toggle mobile menu"
        >
          <MenuIcon size={30} />
        </button>
        {userState.user && token && (
          <MotifDropdown
            ariaLabelledby="dropdown-trigger-1"
            closeOnOutsideClick={false}
            id="dropdown-1"
            placement="bottom-start"
            trigger={
              <div>
                <MotifAvatar
                  className="avatar-portal"
                  description={name}
                  size="medium"
                  userName={name}
                  src={photo ? `data:image/png;base64,${photo}` : undefined}
                  onError={(e) => (e.target.src = undefined)}
                  onClick={() => setDropdownVisible(!dropdownVisible)}
                  data-testid="user-avatar"
                />
              </div>
            }
          >
            <div className="header-dropdown-menu">
              <ul aria-label="profile menu">
                <li>
                  <MotifDropdownItem>
                    <Select
                      aria-labelledby="select-label"
                      className="header-select"
                      onChange={handleLanguageChange}
                      value={languageOptions.find(
                        (option) => option.value === i18n.language
                      )}
                      options={languageOptions}
                    />
                  </MotifDropdownItem>
                </li>
                <span className="motif-dropdown-horizontal-divider" />
                <li>
                  <MotifButton
                    className="header-motif-body"
                    onClick={handleSignOut}
                    data-testid="sign-out-button"
                  >
                    {t("Log out")}
                  </MotifButton>
                </li>
              </ul>
            </div>
          </MotifDropdown>
        )}
      </div>
      {mobileMenuVisible && (
        <div className="mobile-menu show">
          <ul>{renderMenuItems(true)}</ul>
        </div>
      )}
      {loading && (
        <MotifProgressLoader className="loader" show variant="default" />
      )}
    </header>
  );
};

export default Header;
