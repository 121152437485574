const config = {
  baseUrl: window.env?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL,
  apiBaseUrl:
    window.env?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL,
  azureClientId:
    window.env?.REACT_APP_AZURE_CLIENT_ID ||
    process.env.REACT_APP_AZURE_CLIENT_ID,
  azureAppId:
    window.env?.REACT_APP_AZURE_APPLICATION_ID ||
    process.env.REACT_APP_AZURE_APPLICATION_ID,
  appDomain: window.env?.REACT_APP_DOMAIN || process.env.REACT_APP_DOMAIN,
};

console.log("baseUrl:", config.baseUrl);
console.log("apiBaseUrl", config.apiBaseUrl);
console.log("azureClientId", config.azureClientId);
console.log("azureAppId:", config.azureAppId);
console.log("apiPortalBaseUrl:", config.appDomain);

export default config;
