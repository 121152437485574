import React, { useState } from "react";
import PropTypes from "prop-types";
import InstanceImage from "../../assets/images/Instance.png";
import "./AllProjects.scss";
import { MotifButton, MotifLabel } from "@ey-xd/motif-react";
import { RightArrow, BlueTick, Cancel } from "../../constants/icons/Icons";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Instance = ({
  onNext,
  instanceHeader,
  instanceNext,
  instanceModalDescription,
  selectInstance,
  handleInstanceCancel,
  instanceData,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (id) => {
    setSelectedOption(id);
  };

  const handleCancel = () => {
    handleInstanceCancel();
  };

  return (
    <div className="modal-instance">
      <div className="modalContent-instance">
        <div className="img-instance">
          <img src={InstanceImage} alt="instance" />
        </div>
        <div className="text-instance">
          <button
            className="cancel-button"
            onClick={handleCancel}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleCancel();
              }
            }}
          >
            <Cancel />
          </button>
          <div className="text-instance-body">
            <h2
              className="header-instance"
              style={{ fontFamily: "EYInterstate" }}
            >
              {instanceHeader}
            </h2>
            <p
              className="description-instance"
              style={{ fontFamily: "EYInterstate" }}
            >
              {instanceModalDescription}{" "}
              <a
                href="https://sites.ey.com/sites/CorporateSustainabilityReportingDirective/SitePages/ESG-Suite-Request-Form.aspx"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#AFCDFB" }}
              >
                here.
              </a>
            </p>
            <p
              className="description-instance"
              style={{ fontFamily: "EYInterstate" }}
            >
              {selectInstance}{" "}
            </p>

            <div className="body-instance">
              {instanceData?.data?.map((option) => (
                <button
                  className="countries-instance"
                  key={option.instanceLink}
                  onClick={() => {
                    handleClick(option.instanceUiLink);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      handleClick(option.instanceUiLink);
                    }
                  }}
                >
                  <MotifLabel
                    className="countries-instance-label"
                    style={{ fontFamily: "EYInterstate" }}
                  >
                    {option.instanceName}
                  </MotifLabel>
                  {String(selectedOption) === String(option.instanceUiLink) && (
                    <BlueTick />
                  )}
                </button>
              ))}
            </div>

            <MotifButton
              className="nextButtonInstance"
              style={{ fontFamily: "EYInterstate" }}
              onClick={() => onNext({ instanceUiLink: selectedOption })}
              fullWidth={isMobile}
              disabled={!selectedOption}
              title={!selectedOption ? "Please select a instance first" : ""}
            >
              {instanceNext}
              <RightArrow />
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

Instance.propTypes = {
  onNext: PropTypes.func.isRequired,
  instanceHeader: PropTypes.string.isRequired,
  instanceNext: PropTypes.string.isRequired,
  selectInstance: PropTypes.string.isRequired,
  instanceModalDescription: PropTypes.string.isRequired,
  handleInstanceCancel: PropTypes.func.isRequired,
  instanceData: PropTypes.object.isRequired,
};

export default Instance;
